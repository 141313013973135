console.log('loading controllers/application.js');
import { Application } from "@hotwired/stimulus"
const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }

import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

Turbo.setProgressBarDelay(200)

import Masonry from "masonry-layout"
