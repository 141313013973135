/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "core-js/stable"
import "regenerator-runtime/runtime"
import "@hotwired/turbo-rails"

require("@rails/ujs").start();

import 'bootstrap'

document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll('[data-toggle="tooltip"]').tooltip()
})

// hide broken cover image URLs
document.addEventListener('turbo:render', hideBrokenImages)
document.addEventListener("DOMContentLoaded", hideBrokenImages)

function hideBrokenImages(event){
  const cover_imgs = document.querySelectorAll('img.cover-xs, img.cover-sm, img.cover-md, img.cover-lg');

  for (const cover_img of cover_imgs) {
    cover_img.addEventListener("error", function(event) {
      cover_img.style.display = 'inline-block';
      cover_img.style.visibility = 'hidden';
    })
  }
}

require("controllers")

console.log('Hello world from webpacker application.js');
