import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {

    static targets = ['form']

    change(event) {
        console.log("hello")
    }

    update(event) {
        Rails.fire(this.formTarget, 'submit')
        console.log("update")
    }

}
